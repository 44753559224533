.footer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.65);
  left: 0;
  bottom: 0;
  width: 100%;
  color: #333;
  text-align: center;
  padding: 0.25em;
  // font-size: 1.5em;
  font-weight: bolder;
  z-index: 999;

  .copyright {
    margin: 0;
    padding: 0;
    font-size: 0.65em;
  }
}
